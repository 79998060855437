import axios from "axios";
import { Encrypt } from "@/utils/aes.js";
import router from '../router'


const createAxios = (config) => {
  const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 20000,
  });
  // 请求拦截
  service.interceptors.request.use((config) => {
    // 是否短链
    if (router.currentRoute?.value.query?.isShortChain == 1) {
      config.headers.isShortChain = 1;
    }
    const d = new Date();
    config.data = {
      endata: Encrypt(JSON.stringify(config.data || {})),
      ents: Encrypt(parseInt(d.getTime() / 1000) + d.getTimezoneOffset() * 60),
    };
    return config;
  }, undefined);
  return service(config);
};

export default createAxios;
