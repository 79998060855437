import CryptoJS from 'crypto-js'
const { VUE_APP_AES_KEY, VUE_APP_AES_IV } = process.env

//加密方法
export function Encrypt(text, { key, iv } = {}) {
  var srcs = CryptoJS.enc.Utf8.parse(text)
  var encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse(key || VUE_APP_AES_KEY), {
    iv: CryptoJS.enc.Utf8.parse(iv || VUE_APP_AES_IV),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
}

//解密方法
export function Decrypt(text, { key, iv } = {}) {
  let baseResult = CryptoJS.enc.Base64.parse(text) // Base64解密
  let ciphertext = CryptoJS.enc.Base64.stringify(baseResult) // Base64解密
  let decryptResult = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Utf8.parse(key || VUE_APP_AES_KEY), {
    //  AES解密
    iv: CryptoJS.enc.Utf8.parse(iv || VUE_APP_AES_IV),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  let resData = decryptResult.toString(CryptoJS.enc.Utf8).toString()
  return resData
}

