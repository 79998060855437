<template>
  <div style="width: 100%; height: 100%">
    <div v-if="!isImageLoaded" class="loading">
      <svg
        t="1712889762490"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="10548"
        width="50px"
        height="50px"
      >
        <path
          d="M921.897577 650.649803c-57.757448 166.861332-216.07315 287.199071-402.326135 287.199071-234.904031 0-426.016697-191.109596-426.016697-426.019767 0-234.912218 191.112666-426.019767 426.016697-426.019767 95.207385 0 182.91803 31.775704 253.924223 84.754313l12.598946-17.816784c-74.577532-55.503105-166.619832-88.785116-266.522146-88.785116-246.950392 0-447.867354 200.912869-447.867354 447.867354 0 246.953462 200.916962 447.867354 447.867354 447.867354 198.481494 0 366.978069-129.890302 425.573605-309.045635L921.897577 650.650827z"
          :fill="color"
          p-id="10549"
        ></path>
      </svg>
    </div>
    <img
      @load="imageLoaded"
      :src="changeUrl"
      alt=""
      :style="`width: ${width}; height: ${height};border-radius:${radius};object-fit:cover;`"
      v-show="isImageLoaded"
    />
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { Decrypt } from "@/utils/decryptAesImage.js";
import axios from "axios";
export default {
  props: {
    src: {},
    radius: {
      type: String,
      default: "0",
    },
    color: {
      type: String,
      default: "#707070",
    },
    height:{
      type: String,
      default: "100%",
    },
    width:{
      type: String,
      default: "100%",
    },
  },
  setup(props) {
    // 图片是否加载
    const isImageLoaded = ref(false);
    // 原地址
    const originUrl = ref(props.src);
    // 是否为aes
    const isAes = computed(() => originUrl.value?.search(/.aes$/) > -1);
    // 应用地址
    const changeUrl = ref(isAes.value ? "" : originUrl.value);
    // 图片缺省图
    // const errorImg=
    // 图片解析
    function decryptUrl(url) {
      axios({
        url,
        method: "get",
      })
        .then((res) => {
          changeUrl.value = Decrypt(res.data);
        })
        .catch(() => {
          //   changeUrl.value = errorImg.value;
        });
    }
    function imageLoaded() {
      isImageLoaded.value = true;
    }

    watch(
      () => props.src,
      (v) => {
        console.log(v)
        originUrl.value = v;
        changeUrl.value = isAes.value ? "" : originUrl.value;
        isAes.value && decryptUrl(originUrl.value);
      }
    );

    onMounted(() => {
      isAes.value && decryptUrl(originUrl.value);
    });
    return {
      changeUrl,
      isAes,
      imageLoaded,
      isImageLoaded,
    };
  },
};
</script>

<style lang="scss" scoped>
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1.5s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
