import { ref } from 'vue'
import { useStore } from 'vuex'
import service from "@/axios/index.js";

export default function () {
  const { state, commit } = useStore()
  const dragRefs = ref()
  const showDrag = ref(false)
  // 后台查询到的验证图片尺寸：480*240，缺口：50*50，此处缩放比例为： 300/480= 0.625
  let verifyData = ref({
    blocky: 0,
    text: '向右滑动完成拼图',
    h: 150,
    w: 300,
    sh: 0.625 * 70,
    sw: 0.625 * 70
  })
  // 滑块检验是否成功标识
  const isSliderSuccessFlag = ref(false)

  let onSuccess = () => { }
  let verifyResult = (left) => {
    const captchaSliderX = +((left + verifyData.value.sh) / 0.625).toFixed(0)
    service({
      method: "POST",
      url: "/captcha/verifySlider",
      data: {
        captchaSliderId: state.sliderInfo.sliderId, captchaSliderX
      },
    }).then((res) => {
      if (res?.data?.code === 0) {
        dragRefs.value.handleSuccess()
        commit('SET_SLIDER_INFO', { ...state.sliderInfo, sliderX: captchaSliderX })
        isSliderSuccessFlag.value = true
        showDrag.value = false
      } else {
        dragRefs.value.handleFail()
        isSliderSuccessFlag.value = false
      }
    }).catch((err) => {
      dragRefs.value.handleFail()
      isSliderSuccessFlag.value = false
    })
  }

  let onFail = () => {
    setTimeout(() => {
      dragRefs.value.reset()
      dragRefs.value.init()
    }, 1000)
  }
  // 关闭校验
  let onCloseDia = () => {
    isSliderSuccessFlag.value = false
    showDrag.value = false
  }

  // 暴露出去
  return {
    verifyResult,
    verifyData,
    onSuccess,
    onFail,
    dragRefs,
    isSliderSuccessFlag,
    showDrag,
    onCloseDia
  }
}
