import CryptoJS from 'crypto-js'

const aeskey = process.env.VUE_APP_IMGKEY //密钥

//加密方法
function Encrypt(text) {
  let srcs = CryptoJS.enc.Utf8.parse(text)
  let encrypted = CryptoJS.AES.encrypt(srcs, CryptoJS.enc.Utf8.parse(aeskey), {
    mode: CryptoJS.mode.EBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}

//解密方法
function Decrypt(word) {
  let key = CryptoJS.enc.Utf8.parse(aeskey)
  let decrypt = CryptoJS.AES.decrypt(word, key, {
    /*以下两个属性需要和加密对应*/
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}
// 禁止IOS10及以上不识别meta标签的缩放行为
document.addEventListener('gesturestart', function (event) {
  event.preventDefault()
})

export { Encrypt, Decrypt }
