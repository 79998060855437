<template>
  <ElDialog
    :model-value="props.show"
    @update:value="($event) => $emit('update:show', $event)"
    top="28vh"
    width="7.2rem"
    :lock-scroll="false"
    :before-close="handleClose"
    :close-on-click-modal="false"
    class="dialog"
    modal-class="code-number-dialog"
  >
    <div class="canvas">
      <ElInput
        v-model="inputText"
        clearable
        maxlength="4"
        @input="handleInput"
        placeholder="请输入验证码"
        @keyup.enter="handleCheck"
        class="input"
      />
      <canvas
        class="cursor-pointer"
        ref="canvas"
        :width="state.contentWidth"
        :height="state.contentHeight"
        @click="changeCanvasVal"
      />
    </div>

    <template #footer>
      <ElButton type="primary" size="default" @click="handleCheck">
        提交验证
      </ElButton>
    </template>
  </ElDialog>
</template>

<script setup>
import { reactive, ref, watch, nextTick, onMounted } from "vue";
import { ElDialog, ElButton, ElInput, ElMessage } from "element-plus";
const canvas = ref();
const emit = defineEmits(["verifyResult", "update:show"]);

const props = defineProps({
  show: {
    type: Boolean,
  },
});

const state = reactive({
  contentWidth: 120,
  contentHeight: 56,
  //   valicodes: "abcdefghijklmnopqrstuvwxyz1234567890",
  valicodes: "1234567890",
  identifyCode: "",
  count: 4, //个数
});
let inputText = ref("");

watch(
  () => props.show,
  (v) => {
    if (v) nextTick(() => (state.identifyCode = draw()));
  }
);

const handleClose = () => {
  inputText.value = "";
  emit("update:show", false);
};

const handleInput = (value) => {
  inputText.value = value.replace(/\D/g, "");
};

const handleCheck = () => {
  if (!inputText.value) {
    ElMessage({
      type: "error",
      center: true,
      duration: 500,
      grouping: true,
      message: "请输入验证码",
    });
    return;
  }
  if (inputText.value === state.identifyCode) {
    emit("update:show", false);
    emit("verifyResult", true);
    inputText.value = "";
  } else {
    ElMessage({
      type: "error",
      center: true,
      duration: 700,
      grouping: true,
      message: "验证码错误",
    });
    changeCanvasVal();
  }
};

//随机数
const randomNum = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};
//随机颜色
const randomColor = (min, max) => {
  let r = randomNum(min, max);
  let g = randomNum(min, max);
  let b = randomNum(min, max);
  return `rgb(${r},${g},${b})`;
};
//干扰线
const drawLine = (ctx) => {
  for (let i = 0; i < 5; i++) {
    //5条线
    ctx.strokeStyle = randomColor(40, 180);
    ctx.beginPath();
    ctx.moveTo(
      randomNum(0, state.contentWidth),
      randomNum(0, state.contentHeight)
    );
    ctx.lineTo(
      randomNum(0, state.contentWidth),
      randomNum(0, state.contentHeight)
    );
    ctx.stroke();
  }
};
//绘制点
const drawDot = (ctx) => {
  for (let i = 0; i < 20; i++) {
    //20个点
    ctx.fillStyle = randomColor(0, 255);
    ctx.beginPath();
    ctx.arc(
      randomNum(0, state.contentWidth),
      randomNum(0, state.contentHeight),
      1,
      0,
      2 * Math.PI
    );
    ctx.fill();
  }
};
//开始绘制
const draw = () => {
  const ctx = canvas.value.getContext("2d");
  //绘制背景
  ctx.fillStyle = randomColor(220, 255);
  ctx.fillRect(0, 0, state.contentWidth, state.contentHeight);
  // 绘制文字
  let identifyCode = "";

  for (let i = 0; i < state.count; i++) {
    //控制字数
    // drawText(ctx, state.identifyCode[i], i);
    const text = state.valicodes[randomNum(0, state.valicodes.length - 1)];
    identifyCode += text;
    // 字体随机的旋转角度
    var deg = randomNum(-10, 10);
    ctx.font = randomNum(18, 40) + "px Arial"; //字体大小
    ctx.fillStyle = randomColor(50, 160); //字体颜色

    ctx.textBaseline = "middle";

    ctx.save();
    //文字依次随机的上下左右
    let x = (state.contentWidth / state.count) * i + 10;
    let y = randomNum(
      -state.contentHeight / state.count,
      state.contentHeight / state.count
    );
    ctx.translate(x, y);
    ctx.rotate((deg * Math.PI) / 180); //文字旋转角度
    ctx.fillText(text, 0, state.contentHeight / 2);
    ctx.restore();
  }
  //绘制线条
  drawLine(ctx);
  //绘制点
  drawDot(ctx);
  return identifyCode;
};
const changeCanvasVal = () => {
  state.identifyCode = draw();
};

onMounted(() => {
  try {
    document
      .querySelector(".code-number-dialogg")
      .addEventListener("touchstart", function (e) {
        if (e?.touches?.length > 1) {
          e?.preventDefault?.();
        }
      });
    document
      .querySelector(".code-number-dialog")
      .addEventListener("touchmove", function (e) {
        e?.preventDefault?.();
      });
  } catch (error) {}
});
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  align-items: center;
  border-radius: 0.18rem;
}
.canvas {
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
}
.input {
  width: 80%;
}
</style>
