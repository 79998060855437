<template>
  <div>
    <div
      id="slideVerify"
      class="slide-verify"
      :style="widthLable"
      onselectstart="return false;"
    >
      <canvas ref="canvasRef" id="canvasRef" :width="w" :height="h" />
      <canvas
        ref="blockRef"
        id="blockRef"
        class="slide-verify-block"
        :width="w"
        :height="h"
      />
      <div class="slide-verify-refresh-icon">
        <ElIcon><Refresh @click="refresh" /></ElIcon>
        <ElIcon class="ml-1 close-sty" @click="onCloseDia"><Close /></ElIcon>
      </div>
      <div
        class="slide-verify-info"
        :class="{ fail: fail, show: showInfo }"
        @click="refresh"
      >
        {{ infoText }}
      </div>
      <div
        class="slide-verify-slider"
        :style="widthLable"
        :class="{
          'container-active': containerActive,
          'container-success': containerSuccess,
          'container-fail': containerFail,
        }"
      >
        <div
          class="slide-verify-slider-mask"
          :style="{ width: sliderMaskWidth }"
        >
          <!-- slider -->
          <div
            class="slide-verify-slider-mask-item"
            :style="{ left: sliderLeft }"
            @mousedown="sliderDown"
            @touchstart="touchStartEvent"
            @touchmove="touchMoveEvent"
            @touchend="touchEndEvent"
          >
            <ElIcon size="0.3rem"><DArrowRight /></ElIcon>
            <!-- <div class="slide-verify-slider-mask-item-icon el-icon-s-unfold" /> -->
          </div>
        </div>
        <span class="slide-verify-slider-text">{{ sliderText }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  getCurrentInstance,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "vue";
import { useStore } from "vuex";
import service from "@/axios/index.js";
import { ElIcon } from "element-plus";

const props = defineProps({
  // block length
  showDrag: {
    type: Boolean,
    default: false,
  },
  // block length
  l: {
    type: Number,
    default: 42,
  },
  // block radius
  r: {
    type: Number,
    default: 10,
  },
  // canvas width
  w: {
    // 背景图宽
    type: [Number, String],
    default: 200,
  },
  // canvas height
  h: {
    // 背景图高
    type: [Number, String],
    default: 100,
  },
  // canvas width
  sw: {
    // 小图宽
    type: [Number, String],
    default: 50,
  },
  // canvas height
  sh: {
    type: [Number, String],
    default: 50,
  },
  // block_x: {
  //   type: Number,
  //   default: 155
  // },
  blocky: {
    // 小图初始的垂直距离
    type: [Number, String],
    default: 20,
  },
  sliderText: {
    type: String,
    default: "Slide filled right",
  },
  imgurl: {
    // 大图地址
    type: String,
    default: "",
  },
  miniimgurl: {
    // 小图地址
    type: String,
    default: "",
  },
  fresh: {
    type: Boolean,
    default: false,
  },
});
const blockRef = ref("");
const canvasRef = ref("");
const { proxy } = getCurrentInstance();
const { commit, state: store } = useStore();
const emits = defineEmits([
  "refresh",
  "success",
  "fail",
  "verifyResult",
  "onCloseDia",
]);
const state = reactive({
  containerActive: false, // container active class
  containerSuccess: false, // container success class
  containerFail: false, // container fail class
  canvasCtx: null,
  blockCtx: null,
  block: null,
  canvasStr: null,
  L: props.l + props.r * 2 + 3,
  img: undefined,
  originX: undefined,
  originY: undefined,
  isMouseDown: false,
  trail: [],
  widthLable: "",
  sliderLeft: 0, // block right offset
  sliderMaskWidth: 0, // mask width
  dialogVisible: false,
  infoText: "验证成功",
  fail: false,
  showInfo: false,
  imgUrl: "",
  miniImgUrl: "",
  sliderY: "",
});

function sum(x, y) {
  return x + y;
}

function square(x) {
  return x * x;
}

function init() {
  initDom();
  bindEvents();
  state.widthLable = "width:" + props.w + "px;";
}

function initDom() {
  nextTick(() => {
    const canvasStr = document.getElementById("canvasRef");
    state.block = document.getElementById("blockRef");
    state.canvasStr = proxy.$refs.canvasRef;
    state.canvasCtx = canvasStr.getContext("2d");
    state.blockCtx = state.block.getContext("2d");
    initImg();
  });
}

function initImg(h = props.h) {
  const img = document.createElement("img");
  // img.onload = onload
  img.onerror = () => {
    img.src = state.imgUrl;
  };
  img.src = state.imgUrl;
  img.onload = function () {
    state.canvasCtx.drawImage(img, 0, 0, props.w, h);
  };

  state.img = img;
  const img1 = document.createElement("img");
  var blockCtx = state.blockCtx;
  var blocky = h || props.blocky;
  if (blocky === 0) {
    return;
  }
  img1.onerror = () => {
    img1.src = state.miniImgUrl;
  };
  img1.src = state.miniImgUrl;
  img1.onload = function () {
    // blockCtx.drawImage(img1, 0, blocky, that.sw, that.sh)
    blockCtx.drawImage(img1, 0, state.sliderY, 70 * 0.625, 70 * 0.625);
  };
}
// 刷新
function refresh() {
  service({
    method: "POST",
    url: "/captcha/getCaptcha",
    data: {
      captchaType: 4,
      // captchaType: 1,
    },
  }).then((res) => {
    const { sliderBackground, sliderGap, sliderId, sliderX, sliderY } =
      res?.data?.data || {};
    store.sliderInfo = res?.data?.data || {};
    state.imgUrl = sliderBackground;
    state.miniImgUrl = sliderGap;
    state.sliderY = sliderY * 0.625 - 70 * 0.625; // 0.625： 缩放比例  31：缺块高度
    reset();
    // init();
  });
}
function sliderDown(event) {
  state.originX = event.clientX;
  state.originY = event.clientY;
  state.isMouseDown = true;
}
function touchStartEvent(e) {
  if (e?.touches?.length > 1) {
    e?.preventDefault?.();
  }
  state.originX = e.changedTouches[0].pageX;
  state.originY = e.changedTouches[0].pageY;
  state.isMouseDown = true;
}
function bindEvents() {
  document.addEventListener("mousemove", (e) => {
    if (!state.isMouseDown) return false;
    const moveX = e.clientX - state.originX;
    const moveY = e.clientY - state.originY;
    if (moveX < 0 || moveX + 38 >= props.w) return false;
    state.sliderLeft = moveX + "px";
    const blockLeft = ((props.w - 40 - 20) / (props.w - 40)) * moveX;
    state.block.style.left = blockLeft + "px";
    state.containerActive = true; // add active
    state.sliderMaskWidth = moveX + "px";
    state.trail.push(moveY);
  });
  document.addEventListener("mouseup", (e) => {
    if (!state.isMouseDown) return false;
    state.isMouseDown = false;
    if (e.clientX === state.originX) return false;
    state.containerActive = false; // remove active
    verify();
  });
}
function touchMoveEvent(e) {
  e?.preventDefault?.();
  if (!state.isMouseDown) return false;
  const moveX = e.changedTouches[0].pageX - state.originX;
  const moveY = e.changedTouches[0].pageY - state.originY;
  if (moveX < 0 || moveX + 38 >= props.w) return false;
  state.sliderLeft = moveX + "px";
  const blockLeft = ((props.w - 40 - 20) / (props.w - 40)) * moveX;
  state.block.style.left = blockLeft + "px";

  state.containerActive = true;
  state.sliderMaskWidth = moveX + "px";
  state.trail.push(moveY);
}
function touchEndEvent(e) {
  if (!state.isMouseDown) return false;
  state.isMouseDown = false;
  if (e.changedTouches[0].pageX === state.originX) return false;
  state.containerActive = false;
  verify();
}
function verify() {
  const arr = state.trail; // drag y move distance
  const average = arr.reduce(sum) / arr.length; // average
  const deviations = arr.map((x) => x - average); // deviation array
  const stddev = Math.sqrt(deviations.map(square).reduce(sum) / arr.length); // standard deviation
  const left = parseInt(state.block.style.left);
  emits("verifyResult", left, stddev);
}
function reset(h = props.h) {
  state.containerActive = false;
  state.containerSuccess = false;
  state.containerFail = false;
  state.sliderLeft = 0;
  state.block.style.left = 0;
  state.sliderMaskWidth = 0;
  state.canvasCtx.clearRect(0, 0, props.w, h);
  state.blockCtx.clearRect(0, 0, props.w, h);
  state.fail = false;
  state.showInfo = false;
  state.containerFail = false;
  state.containerSuccess = false;
  initImg(h);
}
function handleFail() {
  console.log(111111111111);
  state.fail = true;
  state.showInfo = true;
  state.infoText = "验证失败";
  state.containerFail = true;
  emits("fail");
  setTimeout(() => {
    refresh();
  }, 500);
}
function handleSuccess() {
  state.showInfo = true;
  state.infoText = "验证成功";
  state.containerSuccess = true;
  setTimeout(() => {
    state.block.style.left = 0;
    state.sliderMaskWidth = 0;
    state.sliderLeft = 0;
    state.fail = false;
    state.showInfo = false;
    state.containerSuccess = false;
  }, 1000);
}

function onCloseDia() {
  emits("onCloseDia");
}

watch(
  () => props.fresh,
  (val) => {
    nextTick(() => {
      init();
    });
  }
);
onMounted(() => {
  service({
    method: "POST",
    url: "/captcha/getCaptcha",
    data: {
      captchaType: 4,
      // captchaType: 1,
    },
  }).then((res) => {
    const { sliderBackground, sliderGap, sliderId, sliderX, sliderY } =
      res?.data?.data || {};
    store.sliderInfo = res?.data?.data || {};
    state.imgUrl = sliderBackground;
    state.miniImgUrl = sliderGap;
    state.sliderY = sliderY * 0.625 - 70 * 0.625; // 0.625： 缩放比例  31：缺块高度
    init();
  });
});

const {
  containerActive,
  containerSuccess,
  containerFail,
  canvasCtx,
  blockCtx,
  block,
  canvasStr,
  L,
  img,
  originX,
  originY,
  isMouseDown,
  trail,
  widthLable,
  sliderLeft,
  sliderMaskWidth,
  dialogVisible,
  infoText,
  fail,
  showInfo,
  imgUrl,
  miniImgUrl,
  sliderY,
} = toRefs(state);

defineExpose({
  handleSuccess,
  handleFail,
  initImg,
  init,
  reset,
});
</script>

<style lang="scss" scoped>
.slide-verify {
  position: relative;
  width: 300px;
  overflow: hidden;
}

.slide-verify-block {
  position: absolute;
  left: 0;
  top: 0;
}

.slide-verify-refresh-icon {
  display: flex;
  position: absolute;
  right: 0.2rem;
  top: 0.07rem;
  // width: 1rem;
  height: 0.6rem;
  font-size: 0.4rem;
  line-height: 0.6rem;
  display: flex;
  justify-content: flex-end;
  font-weight: bold;
  color: #226db3;
  .close-sty {
    margin-left: 0.1rem;
  }
}

.slide-verify-slider {
  position: relative;
  text-align: center;
  width: 5.6rem;
  height: 0.72rem;
  line-height: 0.72rem;
  // margin-top: 15px;
  background: #f7f9fa;
  color: #45494c;
  border: 0.018rem solid #e4e7eb;
}

.slide-verify-slider-mask {
  position: absolute;
  left: 0;
  top: 0;
  height: 0.72rem;
  border: 0 solid #1991fa;
  background: #d1e9fe;
}

.slide-verify-info {
  position: absolute;
  bottom: 0.72rem;
  left: 0;
  height: 0.54rem;
  width: 100%;
  color: #fff;
  text-align: center;
  line-height: 0.54rem;
  background-color: #52ccba;
  opacity: 0;
  font-size: 0.25rem;
}
.slide-verify-info.fail {
  background-color: #f57a7a;
}
.slide-verify-info.show {
  animation: hide 1s ease;
}
@keyframes hide {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}
.slide-verify-slider-mask-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.72rem;
  height: 0.72rem;
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: background 0.2s linear;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-verify-slider-mask-item:hover {
  background: #1991fa;
}

.slide-verify-slider-mask-item:hover .slide-verify-slider-mask-item-icon {
  background-position: 0 -0.23rem;
}

.slide-verify-slider-mask-item-icon {
  position: absolute;
  top: 0.16rem;
  left: 0.12rem;
  width: 0.25rem;
  height: 0.21rem;
  content: "法币";
  font-size: 0.4rem;
  color: #ddd;
}
.container-active .slide-verify-slider-mask-item {
  height: 0.69rem;
  top: -0.018rem;
  border: 0.018rem solid #1991fa;
}

.container-active .slide-verify-slider-mask {
  height: 0.69rem;
  border-width: 0.018rem;
}

.container-success .slide-verify-slider-mask-item {
  height: 0.69rem;
  top: -0.018rem;
  border: 0.018rem solid #52ccba;
  background-color: #52ccba !important;
}

.container-success .slide-verify-slider-mask {
  height: 0.69rem;
  border: 0.018rem solid #52ccba;
  background-color: #d2f4ef;
}

.container-success .slide-verify-slider-mask-item-icon {
  background-position: 0 0 !important;
}

.container-fail .slide-verify-slider-mask-item {
  height: 0.69rem;
  top: -0.018rem;
  border: 0.018rem solid #f57a7a;
  background-color: #f57a7a !important;
}

.container-fail .slide-verify-slider-mask {
  height: 0.69rem;
  border: 0.018rem solid #f57a7a;
  background-color: #fce1e1;
}

.container-fail .slide-verify-slider-mask-item-icon {
  top: 0.25rem;
  background-position: 0 -82px !important;
}
.slide-verify-slider-text {
  font-size: 0.26rem;
}

.container-active .slide-verify-slider-text,
.container-success .slide-verify-slider-text,
.container-fail .slide-verify-slider-text {
  display: none;
}

:deep(.slider-sty) {
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
  }
}
</style>
